<template>
  <div class="select-self">
    <div
      class="show"
      @click="handleSelect"
      :style="{ boxShadow: visible ? '0 2px 12px #c8cbce' : 'none' }"
    >
      {{ selectedText }}
      <van-icon :name="visible ? 'arrow-up' : 'arrow-down'" />
    </div>

    <div class="van-list" v-if="visible">
      <div
        :class="{ selected: item.value == selectedValue, 'van-cell': true }"
        v-for="item in options"
        :key="item.value"
        @click="selectCell(item)"
      >
        <div class="van-cell__title">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    options: [],
    handleCurrent: Function,
  },
  data() {
    return {
      visible: false,
      flag: false,
      selectedValue: this.options[0].value,
      selectedText: this.options[0].text,
    };
  },
  methods: {
    handleSelect() {
      this.flag = true;
      this.visible = !this.visible;
      this.handleCurrent(this.selectedValue);
    },
    selectCell(item) {
      this.selectedValue = item.value;
      this.selectedText = item.text;
      this.handleCurrent(this.selectedValue);
      this.visible = false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener("click", (e) => {
        if (!this.flag) {
          let target = document.querySelector(".select-self");
          this.visible = !(target && !target.contains(e.target));
        }
        this.flag = false;
      });
    });
  },
});
</script>

<style scoped lang="scss">
.select-self {
  width: 1rem;
  z-index: 100;
  .show {
    background: #fff;
    font-size: 0.12rem;
    height: 0.36rem;
    line-height: 0.36rem;
  }
  .selected {
    background: #e6ebf8;
  }
  .van-list {
    box-shadow: 0 2px 12px #c8cbce;
    background: #fff;
    max-height: 2.6rem;
    overflow: scroll;
  }
}
</style>
