<template>
  <div>
    <van-grid :cloumn-num="4" square>
      <van-grid-item>
        <span>平均热量</span>
        <strong>{{ state.averageCaloric }}</strong>
        <span>
          <van-icon name="bar-chart-o" color="#475bb1" />
          {{
            Math.abs(
              ((state.averageCaloric - lastDay.averageCaloric) /
                lastDay.averageCaloric) *
                100
            ).toFixed(2)
          }}%
        </span>
      </van-grid-item>
      <van-grid-item>
        <span>平均温度</span>
        <strong>{{ state.averageTemperature }}</strong>
        <span>
          <van-icon name="bar-chart-o" color="#475bb1" />
          {{
            Math.abs(
              ((state.averageTemperature - lastDay.averageTemperature) /
                lastDay.averageTemperature) *
                100
            ).toFixed(2)
          }}%
        </span>
      </van-grid-item>
      <van-grid-item>
        <span>总楼栋数</span>
        <strong>{{ state.countBuilding }}</strong>
        <span class="span_color">
          <van-icon name="bar-chart-o" color="#475bb1" />
        </span>
      </van-grid-item>
      <van-grid-item>
        <span>总户数</span>
        <strong>{{ state.countRoom }}</strong>
        <span class="span_color">
          <van-icon name="bar-chart-o" color="#475bb1" />
        </span>
      </van-grid-item>
    </van-grid>

    <div class="show-chart">
      <Select :options="charts" :handleCurrent="handleCurrent" />
      <div class="my-chart" @click="handleClick" />
    </div>
  </div>
</template>

<script>
import {
  inject,
  ref,
  onMounted,
  getCurrentInstance,
  defineComponent,
} from "vue";
import Select from "../components/Select";
import { useRouter } from "vue-router";
import { Toast } from "vant";

export default defineComponent({
  name: "Home",
  components: {
    Select,
  },
  setup(_, context) {
    const router = useRouter();
    const { proxy } = getCurrentInstance();

    let echarts = inject("ec");

    const charts = [
      { value: "line", text: "折线图" },
      { value: "bar", text: "柱状图" },
    ];

    const state = ref({});
    const currentChart = ref("line");
    const lastDay = ref({});
    const data = ref([]);
    const seriesRef = ref({
      averageTemperature: [],
      averageFlow: [],
    });

    const renderChart = () => {
      let myChart = echarts.init(document.querySelector(".my-chart"));

      let option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          bottom: "0",
          data: ["平均温度", "平均流量"],
        },
        toolbox: {
          feature: {
            // saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: currentChart.value == "bar",
          data: data.value,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "平均温度",
            type: currentChart.value,
            data: seriesRef.value.averageTemperature,
          },
          {
            name: "平均流量",
            type: currentChart.value,
            data: seriesRef.value.averageFlow,
          },
        ],
      };

      myChart.setOption(option);
    };

    const getStr = (time) => time.toString().padStart(2, "0");

    const getList = async () => {
       context.emit('changeLoading',true)
      const userInfo = JSON.parse(localStorage.getItem("user_info"));

      let res = await proxy.axios.get(
        "/api/base/home?communityId=" + userInfo[0].communityId
      );

      if (res.code == 200 && res.msg == "success") {
        state.value = res.data;
         context.emit('changeLoading',false)

        let tempData = [],
          aveTemp = [],
          aveFlow = [];

        let temp = res.data.dailyReportList.sort((a, b) =>
          a.day < b.day ? -1 : 0
        );

        //当前日期的前一天
        const date = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
        let last =
          date.getFullYear() +
          "-" +
          getStr(date.getMonth() + 1) +
          "-" +
          getStr(date.getDate());

        temp.map((v) => {
          tempData.push(v.day.substr(5));
          aveTemp.push(v.averageTemperature);
          aveFlow.push(v.averageFlow);

          lastDay.value = v.day == last ? v : null;
        });

       

        lastDay.value = lastDay.value || temp[temp.length - 1];

        data.value = tempData;
        seriesRef.value.averageTemperature = aveTemp;
        seriesRef.value.averageFlow = aveFlow;

        
       setTimeout(() => {
          renderChart()
       })
      } else {
        Toast("数据获取失败，正在重新加载！");
        getList();
      }
    };

    const handleClick = () => {
      router.push({ path: "/history", query: { type: "home" } });
    };

    const handleCurrent = (val) => {
      currentChart.value = val;
      renderChart();
    };

    router.beforeEach((to) => {
      if (to.path == "/history") {
        const temp = {
          home: "小区历史数据",
          household: "用户历史",
          warn: "故障历史",
        };
        if (to.params.info) {
          let info = JSON.parse(to.params.info);
          to.meta.title = `${info.buildingId}号楼${info.unitId}单元${
            info.roomId
          }${to.query.type == "warn" ? "故障历史" : "信息"}`;
        } else {
          to.meta.title = temp[to.query.type] || to.meta.title;
        }
      }
    });

    onMounted(() => {
      getList();
    });

    return {
      state,
      charts,
      lastDay,
      currentChart,
      handleCurrent,
      handleClick,
    };
  },
});
</script>

<style scoped lang="scss">
.van-grid {
  font-size: 0.12rem;
  text-align: center;
  strong {
    width: 100%;
    font-size: 0.22rem;
    height: 0.6rem !important;
  }
  span {
    width: 100%;
    &.span_color {
      visibility: hidden;
    }
  }
}
.show-chart {
  position: relative;
  .my-chart {
    margin: 0.02rem 0 0;
    height: 3rem;
  }
  .select-self {
    position: absolute;
    top: 0;
    left: 0.1rem;
    display: none;
  }
}
</style>
